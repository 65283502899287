import * as React from "react"
import PropTypes from "prop-types"

import TopDark from "../components/Modules/Top/TopDark"
import Footer from "../components/Modules/Footer"


// STYLES 
import "../styles/styles.scss"


const Layout = ({ children }) => {

  return (
    <>
      <TopDark />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
