import * as React from "react"
import {Link} from "gatsby"
import {Container, Row, Col} from 'react-bootstrap'
import LayoutDark from '../components/layoutDark'
import Seo from "../components/seo"
import RefButton from "../components/Elements/Buttons/RefButtons/RefStyledButtons/RefButton"

const NotFoundPage = () => (
  <>
    <Seo 
      title="Этой страницы не существует." 
      description="Ошибка 404! Данной страницы на сайте Рефинжиниринг не существует."
    />
    <LayoutDark>
    <div className="ref-container">
    <Container fluid>
        <Row>
          <Col>
            <h1 style={{display: 'grid', justifyContent: 'center'}}>404: Такой страницы у нас нет.</h1>
          </Col>
        </Row>
        <Container >
        <Row>
          <Col>
            <p>Возможно вы попали сюда по ошибке.</p>
            <p>Все страницы которые есть на сайте удобно представлены в виде ссылок на <a href="/sitemap">HTML карте сайта.</a></p>
            <p>Вернитесь пожалуйста назад или на <a href="/">главную страницу сайта.</a></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Также вы можете посмотреть все проекты компании:</p>
            <Link to="/projects/"><RefButton>ВСЕ ПРОЕКТЫ</RefButton></Link>
          </Col>
        </Row>
        <Row>
          <p></p>
        </Row>
        <Row>
          <Col>
            <p>Или отправить нам сообщение:</p>
            <Link to="/contacts/"><RefButton>КОНТАКТЫ</RefButton></Link>
          </Col>
        </Row>
        </Container>
    </Container>
    </div>
    </LayoutDark>
  </>
)

export default NotFoundPage
